import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { EmailSubjectLine } from "../molecules/EmailSubjectLine";
import { editor, moduleNameMap } from "../../config/config";
import { EmailLineContainer } from "../atoms/EmailLineContainer";
import { zohoApi } from "../../zohoApi";
import { staticToDynamicEmail, validateEmail } from "../../util/util";

export const EmailComponentPreview = ({
  module,
  relatedListModule,
  tempModule,
  finalRecords,
  fromEmail,
  subject,
  emailContent,
  handlePreviewClose,
}) => {
  // console.log({ finalRecords });
  const [tempSub, setTempSub] = React.useState(subject);
  const [tempBody, setTempBody] = React.useState(emailContent);
  const [dataLoading, setDataLoading] = React.useState(false);
  //---------------
  React.useEffect(() => {
    const replaceSignature = async ({ emailContent, fromEmail }) => {
      const { data } = await zohoApi.user.getCrmUsersSignature({
        email: fromEmail,
      });
      let tempContent = emailContent.replace("${!userSignature}", data || "");
      setTempBody(tempContent);
    };
    if (
      validateEmail(fromEmail) &&
      emailContent !== "" &&
      emailContent.includes("${!userSignature}")
    ) {
      replaceSignature({ emailContent, fromEmail });
    }
  }, [fromEmail, emailContent]);
  //---------------

  // let tempModule = !!relatedListModule ? relatedListModule : module;

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <EmailLineContainer>
        <Autocomplete
          size="small"
          onChange={async (e, value) => {
            if (value?.id) {
              setDataLoading(true);
              const data = await zohoApi.record.getFieldsAndData({
                entity: tempModule,
                entityId: value?.id,
              });
              const tempSub = await staticToDynamicEmail(
                subject,
                data?.recordData
              );
              setTempSub(tempSub);
              const tempEmailBody = await staticToDynamicEmail(
                tempBody,
                data?.recordData
              );
              setTempBody(tempEmailBody);
              setDataLoading(false);
            }
          }}
          disablePortal
          options={finalRecords}
          getOptionLabel={(obj) => {
            // console.log({
            //   obj,
            //   tempModule,
            //   module,
            //   relatedListModule,
            //   t: moduleNameMap(tempModule),
            // });
            return obj?.[moduleNameMap(tempModule)];
          }}
          renderInput={(params) => <TextField {...params} label="Record" />}
        />
      </EmailLineContainer>
      <EmailSubjectLine
        onChange={(e) => {}}
        value={tempSub}
        richTextEditor={false}
        onSelectRichTextEditor={() => {}}
      />

      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          borderTop: "1px solid #d7e2ed",
          position: "relative",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            inset: ".5em",
            overflowY: "auto",
          }}
        >
          {dataLoading ? (
            <Box
              sx={{ minHeight: "100%", display: "grid", placeItems: "center" }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box
              sx={{ minHeight: "100%" }}
              dangerouslySetInnerHTML={{ __html: tempBody }}
            />
          )}
        </Box>
      </Box>
      <EmailLineContainer sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          // disabled={ctaButtonInLoadingState}
          sx={{
            fontSize: "medium",
            fontWeight: "bold",
            textTransform: "capitalize",
            py: 0.15,
            px: "10px",
            mr: ".5em",
            height: "100%",
          }}
          variant="outlined"
          disableRipple
          onClick={() => {
            setTempSub(null);
            setTempBody(null);
            handlePreviewClose();
          }}
        >
          Close Preview
        </Button>
      </EmailLineContainer>
    </Box>
  );
};
