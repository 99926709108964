const ZOHO = window.ZOHO;

export async function getCrmUsersEmail({ accessToken, dataCenterUrl }) {
  try {
    const url = `${dataCenterUrl}/crm/v6/settings/emails/actions/from_addresses`;
    var req_data = {
      url,
      param_type: 1,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    const resp = await ZOHO.CRM.HTTP.get(req_data);
    const resp_json = await JSON.parse(resp);

    const filteredEmails = await resp_json?.from_addresses?.filter(
      (el) => el.type === "org_email"
    );

    const approvedEmails = await filteredEmails?.map(
      (indvFromMap) => indvFromMap?.email
    );

    // if (approvedEmails?.length === 0) {
    //   return {
    //     data: null,
    //     error:
    //       "Your organization has no approved email. Please get approved atleast one email",
    //   };
    // }

    return { data: approvedEmails, error: null };
  } catch (crmUsersEmailError) {
    return { data: null, error: "Your organization has no approved email" };
  }
}

export async function getCrmUsersSignature({ email }) {
  // const loggedinUserResp = await ZOHO.CRM.CONFIG.getCurrentUser();
  try {
    const userResp = await ZOHO.CRM.API.searchRecord({
      Entity: "users",
      Type: "criteria",
      Query: `(email:equals:${email})`,
    });
    const userId = await userResp?.users?.[0]?.id;
    if (userId) {
      const userDetailsResp = await ZOHO.CRM.API.getUser({ ID: userId });
      const userSign = await userDetailsResp?.users?.[0]?.signature;
      return { data: userSign || "", error: null };
    } else {
      return { data: null, error: "Something went wrong" };
    }

    // const corrected = await userSign.replace(
    //   "<br>",
    //   "<br/>"
    // );
  } catch (getCrmUsersSignatureError) {
    return { data: null, error: "Something went wrong" };
  }
}

export const user = { getCrmUsersEmail, getCrmUsersSignature };
