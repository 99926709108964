import * as React from "react";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { Dialog } from "../components/organisms/Dialog";
import { Table } from "../components/organisms/Table";
import { EmailComponent } from "../components/templates/EmailComponent";
import { EmailComponentPreview } from "../components/templates/EmailComponentPreview";
import { useZohoAuth } from "../hook/useZohoAuth";
import { zohoApi } from "../zohoApi";
import {
  editor,
  buttonPositions,
  moduleNameMap,
  specialModules,
} from "../config/config";
import { InitialPage } from "../components/organisms/InitialPage";
import { validateEmail } from "../util/util";
import {
  EXTENSION_IDENTIFIER,
  ZOHO_CONN_NAME,
} from "../config/serviceConstant";
// import { enqueueSnackbar } from "notistack";

const { useState, useEffect } = React;

const maxWindow = { height: "80%", width: "80%" };
const minWindow = { height: "350", width: "700" };
const minWindowBlueprint = { height: "410", width: "700" };

export const ButtonRoute = () => {
  const {
    module,
    recordId,
    initZoho,
    accessToken,
    buttonPosition,
    orgId,
    dataCenterUrl,
    zapiKey,
    adminEmail,
    apiKey,
  } = useZohoAuth();

  const [storage, setStorage] = useState();
  const [openTable, setOpenTable] = useState(false);
  const [recordDetailsArr, setRecordDetailsArr] = useState();
  //-------------------
  const [relatedListModule, setRelatedListModule] = useState();
  const [relationshipName, setRelationshipName] = useState();
  const [openEmail, setOpenEmail] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [firstPage, setFirstPage] = useState();
  const [relatedListApis, setRelatedListApis] = useState([]);
  const [relatedListLoading, setRelatedListLoading] = useState();
  const [singleRecordDataLoading, setSingleRecordDataLoading] = useState();
  const [crmUsersEmail, setUsersCrmEmails] = useState([]);
  const [crmUsersEmailLoading, setCrmUsersEmailLoading] = useState(false);
  const [fromEmail, setFromEmail] = useState("");
  //-------------------
  const [subject, setSubject] = useState("");
  const [emailContent, setEmailContent] = React.useState("");
  const [editorType, setEditorType] = useState(editor.rich_text);
  //-------------------
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [emailTemplatesLoading, setEmailTemplatesLoading] = useState(false);
  //-------------------
  const [selectedEmailTemplateId, setSelectedEmailTemplateId] = useState();
  //-------------------
  const [inventoryTemplates, setInventoryTemplates] = useState([]);
  const [inventoryTemplatesLoading, setInventoryTemplatesLoading] =
    useState(false);
  const [selectedInventoryTemplate, setSelectedInventoryTemplate] = useState(
    []
  );
  //-------------------
  const [selectedFallbackEmail, setSelectedFallbackEmail] = React.useState("");
  const [isOwnerEmailSelected, setIsOwnerEmailSelected] = React.useState(false);
  //-------------------
  const [emailFields, setEmailFields] = useState([]);
  const [fileFields, setFileFields] = useState([]);
  const [selected, setSelected] = React.useState([]);
  const [notSelected, setNotSelected] = React.useState([]);
  const [initPageContent, setInitPageContent] = React.useState(
    <CircularProgress />
  );
  //-------------------
  const [emailType, setEmailType] = useState();
  let tempModule = !!relatedListModule ? relatedListModule : module;
  // console.log({ module, relatedListModule, tempModule });
  //-------------------
  const listViewPage = buttonPosition === buttonPositions.ListView;
  const detailViewPage = buttonPosition === buttonPositions.DetailView;
  const listViewEachRecordPage =
    buttonPosition === buttonPositions.ListViewEachRecord;
  const listViewWithoutRecordPage =
    buttonPosition === buttonPositions.ListViewWithoutRecord;
  const blueprintPage = buttonPosition === buttonPositions.BlueprintView;
  //-------------------

  useEffect(() => {
    if (accessToken === null) {
      setInitPageContent(<h1>Not authenticated.</h1>);
    }
  }, [accessToken]);

  useEffect(() => {
    async function getCrmEmails({ accessToken, dataCenterUrl }) {
      setCrmUsersEmailLoading(true);
      const { data: resp, error } = await zohoApi.user.getCrmUsersEmail({
        accessToken,
        dataCenterUrl,
      });
      // if (error || !resp || resp?.length == 0) {

      //   // setInitPageContent(error);
      //   setFirstPage((prev) => false);
      //   setInitPageContent(<h1>{error}</h1>);
      //   // setFirstPage((prev) => false);
      //   enqueueSnackbar(error || `Something went wrong`, {
      //     variant: "error",
      //     autoHideDuration: 3000,
      //   });
      // }
      setUsersCrmEmails(resp);
      setFromEmail(resp?.[0]);
      if (resp?.[0]) {
        setEmailType({ type: "Org email", orgEmail: true });
      } else {
        setEmailType({ type: null, orgEmail: false });
      }
      setCrmUsersEmailLoading(false);
    }
    if (accessToken && dataCenterUrl) {
      getCrmEmails({ accessToken, dataCenterUrl });
    }
  }, [accessToken, dataCenterUrl]);

  useEffect(() => {
    if (listViewWithoutRecordPage) {
      setInitPageContent(<h1>Please select record</h1>);
    }
  }, [listViewWithoutRecordPage]);

  useEffect(() => {
    if (module && recordId) {
      setStorage({
        module,
        recordId: Array.isArray(recordId) ? recordId?.[0] : recordId,
      });
    }
  }, [module, recordId, recordDetailsArr]);

  useEffect(() => {
    const fetchRelatedListApis = async ({
      module,
      accessToken,
      dataCenterUrl,
    }) => {
      const { data: resp } = await zohoApi.record.getRelatedListApis({
        module,
        accessToken,
        dataCenterUrl,
      });
      // setRelatedListApis(resp);
      //---------
      const temp = [];
      for (const obj of resp) {
        const { data } = await zohoApi.record.getRecordsFromRelatedList({
          module,
          recordId,
          RelatedListAPI: obj?.api_name,
        });
        if (data?.length > 0) {
          temp.push(obj);
        }
      }
      let temp2 = [];
      for (const obj of temp) {
        const { data } = await zohoApi.record.getEmailFields({
          module: obj?.module?.api_name,
          accessToken,
          dataCenterUrl,
        });

        if (data?.length > 0) {
          temp2.push(obj);
        }
      }
      // console.log({ temp, temp2 });
      setRelatedListApis(temp2);
      setFirstPage(true);
    };
    if (
      module &&
      accessToken &&
      dataCenterUrl &&
      (detailViewPage || blueprintPage || listViewEachRecordPage)
    ) {
      // setFirstPage(true);
      fetchRelatedListApis({ module, accessToken, dataCenterUrl });
    }
  }, [
    module,
    accessToken,
    detailViewPage,
    blueprintPage,
    listViewEachRecordPage,
    dataCenterUrl,
  ]);

  const fetchRecordDetails = async ({ accessToken, url, tempEmails }) => {
    const { data } = await zohoApi.record.getBulkRecordDetailsRestAPI({
      accessToken,
      url,
    });
    setRecordDetailsArr(data);
    const passedData =
      data?.length > 1
        ? data?.filter((obj) => {
            let val = false;
            tempEmails?.forEach((key) => {
              if (obj[key] !== null) {
                val = true;
                return;
              }
            });
            return val;
          })
        : data;

    const passedIdArr = passedData?.map((el) => el?.id);

    setSelected(passedIdArr);

    const failedData = data?.filter((obj) => {
      let val = false;
      tempEmails?.forEach((key) => {
        if (obj[key] !== null) {
          val = true;
          return;
        }
      });
      return !val;
    });

    const failedIdArr = failedData?.map((el) => el?.id);
    setNotSelected(failedIdArr);
  };

  const fetchEmailAndFileFields = async ({
    module,
    accessToken,
    dataCenterUrl,
  }) => {
    // const { data: allFieldsData } =
    //   await zohoApi.record.getRecordsEmailAndLookupEmail({
    //     module,
    //     dataCenterUrl,
    //     record_id: recordId[0],
    //     orgId,
    //     apiKey,
    //     connName: ZOHO_CONN_NAME,
    //   });

    const { data } = await zohoApi.record.getEmailAndFileFields({
      module,
      accessToken,
      dataCenterUrl,
    });

    const tempEmails = await data?.emailFields;
    setEmailFields(tempEmails);
    const fileFields = await data?.fileFields;
    setFileFields(fileFields);
    return tempEmails?.map((el) => el.api_name);
  };

  const fetchEmailTemplates = async ({
    module,
    accessToken,
    dataCenterUrl,
  }) => {
    setEmailTemplatesLoading(true);
    const { data: templates } = await zohoApi.email.getEmailTemplateList({
      module,
      accessToken,
      dataCenterUrl,
    });
    setEmailTemplates(templates);
    setEmailTemplatesLoading(false);
  };

  const fetchInventoryTemplates = async ({
    module,
    accessToken,
    dataCenterUrl,
  }) => {
    setInventoryTemplatesLoading(true);

    const { data: inventoryTemplates } =
      await zohoApi.inventory.getInventoryTemplateList({
        module,
        accessToken,
        dataCenterUrl,
      });

    if (inventoryTemplates?.length < 1) {
      setInitPageContent(<h1>No inventory template.</h1>);
      return;
    }

    setInventoryTemplates(inventoryTemplates);
    setInventoryTemplatesLoading(false);
  };

  const fetchData = React.useCallback(
    async ({ recordId, tempEmails }) => {
      let ids = "?ids=" + recordId?.join(",");
      let fields =
        `&fields=Owner,${moduleNameMap(module)},` + tempEmails?.join(",");
      const dynamic = module + ids + fields;
      const url = `${dataCenterUrl}/crm/v6/${dynamic}`;

      await fetchRecordDetails({ accessToken, url, tempEmails });

      await fetchEmailTemplates({ module, accessToken, dataCenterUrl });

      if (specialModules.includes(module)) {
        await fetchInventoryTemplates({ module, accessToken, dataCenterUrl });
      }

      await zohoApi.auth.resizeWindow(maxWindow);
    },
    [accessToken, module, dataCenterUrl]
  );

  useEffect(() => {
    const fetchListViewData = async () => {
      if (recordId?.length > 100) {
        setInitPageContent(<h1>Too many records selected.</h1>);
        return;
      }

      const tempEmails = await fetchEmailAndFileFields({
        module,
        accessToken,
        dataCenterUrl,
      });

      if (tempEmails?.length === 0) {
        setInitPageContent(<h1>No email fields in this module.</h1>);
        return;
      }

      await fetchData({ recordId, tempEmails });

      setOpenTable(true);
    };
    if (module && recordId && accessToken && listViewPage && dataCenterUrl) {
      fetchListViewData();
    }
  }, [module, recordId, accessToken, fetchData, listViewPage, dataCenterUrl]);

  const onClickSingleRecord = async () => {
    const fetchSingleRecord = async () => {
      setSingleRecordDataLoading(true);
      setRelatedListModule(undefined);
      setRelationshipName(undefined);
      setRecordDetailsArr(null);
      setSelected([]);

      const tempEmails = await fetchEmailAndFileFields({
        module,
        accessToken,
        dataCenterUrl,
      });

      await fetchData({
        recordId: Array.isArray(recordId) ? recordId : [recordId],
        tempEmails,
      });

      setOpenEmail(true);
      setSingleRecordDataLoading(false);
    };
    if (module && recordId && accessToken) {
      fetchSingleRecord();
    }
  };

  const fetchRelatedListRecords = async ({ RelatedListAPI, tempEmails }) => {
    const { data } = await zohoApi.record.getRecordsFromRelatedList({
      module,
      recordId,
      RelatedListAPI,
    });
    setRecordDetailsArr(data);

    const failedData = data?.filter((obj) => {
      let val = false;
      tempEmails?.forEach((key) => {
        if (obj[key] !== null) {
          val = true;
          return;
        }
      });
      return !val;
    });

    const failedIdArr = failedData?.map((el) => el?.id);
    setNotSelected(failedIdArr);

    return data?.length;
  };

  const onSelectAutocomplete = async (e, value) => {
    if (value) {
      setRelatedListLoading(true);
      const newModule = value?.module?.api_name;

      const relationshipName = value?.api_name;

      setRelatedListModule((prev) => newModule);
      setRelationshipName((prev) => relationshipName);
      setRecordDetailsArr(null);
      setSelected([]);

      const tempEmails = await fetchEmailAndFileFields({
        module: newModule,
        accessToken,
        dataCenterUrl,
      });

      if (tempEmails?.length === 0) {
        setInitPageContent(<h1>No email fields in this module.</h1>);
        handleFirstPageClose();
        return;
      }

      const recordLength = await fetchRelatedListRecords({
        RelatedListAPI: relationshipName,
        tempEmails,
      });

      if (recordLength < 1) {
        setInitPageContent(<h1>No record found in Related Module</h1>);
        handleFirstPageClose();
        return;
      }

      await fetchEmailTemplates({
        module: newModule,
        accessToken,
        dataCenterUrl,
      });

      if (specialModules.includes(newModule)) {
        await fetchInventoryTemplates({
          module: newModule,
          accessToken,
          dataCenterUrl,
        });
      }

      await zohoApi.auth.resizeWindow(maxWindow);

      setOpenTable(true);
      setRelatedListLoading(false);
    }
  };

  const onTableClose = () => {
    setOpenEmail(true);
    setOpenTable(false);
  };

  const onSelectAllTableItem = () => {
    setSelected((pre) => {
      const tempEmailFields = emailFields?.map((el) => el.api_name);
      const passedData = recordDetailsArr?.filter((obj) => {
        let val = false;
        tempEmailFields?.forEach((key) => {
          if (obj[key] !== null) {
            val = true;
            return;
          }
        });
        return val;
      });
      return pre?.length === 0 ? passedData.map((el) => el.id) : [];
    });
  };

  const onSelectSingleTableItem = (rowId) => {
    setSelected((pre) =>
      pre.includes(rowId) ? pre.filter((id) => id !== rowId) : [...pre, rowId]
    );
  };

  const getEmailFields = () => {
    if (selected?.length > 1) {
      return emailFields;
    }
    if (selected?.length === 1) {
      const tempRecObj = recordDetailsArr?.filter((el) =>
        selected.includes(el.id)
      );
      let emailArray = [];
      emailFields?.forEach(({ api_name }) => {
        const email = tempRecObj?.[0][api_name];
        if (validateEmail(email)) {
          emailArray.push(email);
        }
      });
      return [...new Set(emailArray)];
    }
    return [];
  };

  const getFinalRecords = () => {
    return recordDetailsArr?.filter((obj) => selected.includes(obj?.id));
  };

  const handleFirstPageClose = () => {
    setFirstPage(false);
  };

  const handleTableBack = () => {
    zohoApi.auth.resizeWindow(blueprintPage ? minWindowBlueprint : minWindow);
    setOpenTable(false);
  };

  const handleEmailClose = () => {
    setOpenEmail(false);
    zohoApi.auth.resizeWindow(blueprintPage ? minWindowBlueprint : minWindow);
  };

  const handlePreviewClose = () => {
    setOpenPreview(false);
    // zohoApi.auth.resizeWindow(blueprintPage ? minWindowBlueprint : minWindow);
  };

  useEffect(() => {
    const r = async () => {
      await zohoApi.auth.resizeWindow(minWindow);
    };
    if ((detailViewPage || listViewEachRecordPage) && initZoho) {
      r();
    }
  }, [detailViewPage, listViewEachRecordPage, initZoho]);

  useEffect(() => {
    const r = async () => {
      await zohoApi.auth.resizeWindow(minWindowBlueprint);
    };
    if (blueprintPage && initZoho) {
      r();
    }
  }, [blueprintPage, initZoho]);

  return (
    <>
      <Box
        sx={{
          height: "100vh",
          pt: "30px",
          pl: "30px",
          pr: "30px",
          bgcolor: "rgba(245, 245, 245, 1)",
          display: "grid",
          placeItems: "center",
        }}
      >
        {initPageContent}
      </Box>

      <Dialog dialogOpen={firstPage} handleDialogClose={() => {}}>
        <InitialPage
          singleRecordDataLoading={singleRecordDataLoading}
          relatedListLoading={relatedListLoading}
          onClickSingleRecord={onClickSingleRecord}
          relatedListApis={relatedListApis}
          onSelectAutocomplete={onSelectAutocomplete}
          customActionPage={false}
          blueprintPage={blueprintPage}
        />
      </Dialog>

      <Dialog dialogOpen={openTable} handleDialogClose={handleTableBack}>
        <Table
          module={relatedListModule ? relatedListModule : module}
          rows={recordDetailsArr}
          handleSelectRow={onSelectSingleTableItem}
          handleSelectAllClick={onSelectAllTableItem}
          selected={selected}
          emailFields={emailFields}
          onTableClose={onTableClose}
          handleTableBack={handleTableBack}
          listViewPage={listViewPage}
          notSelected={notSelected}
        />
      </Dialog>

      <Dialog dialogOpen={openEmail} handleDialogClose={handleEmailClose}>
        <EmailComponent
          module={module}
          recordId={recordId}
          orgId={orgId}
          zapiKey={zapiKey}
          accessToken={accessToken}
          relatedListModule={relatedListModule}
          setRelatedListModule={setRelatedListModule}
          relationshipName={relationshipName}
          setRelationshipName={setRelationshipName}
          freeSolo={getFinalRecords()?.length === 1}
          noParentRecord={buttonPosition === buttonPositions.ListView}
          handleEmailBack={() => {
            if (listViewPage) {
              setOpenTable(true);
            } else {
              handleEmailClose();
            }
          }}
          //------
          crmUsersEmail={crmUsersEmail}
          crmUsersEmailLoading={crmUsersEmailLoading}
          //------
          selectedEmailTemplateId={selectedEmailTemplateId}
          setSelectedEmailTemplateId={setSelectedEmailTemplateId}
          //------
          emailType={emailType}
          setEmailType={setEmailType}
          //------
          fromEmail={fromEmail}
          setFromEmail={setFromEmail}
          //------
          emailTemplates={emailTemplates}
          emailTemplatesLoading={emailTemplatesLoading}
          //------
          inventoryTemplates={inventoryTemplates}
          inventoryTemplatesLoading={inventoryTemplatesLoading}
          selectedInventoryTemplate={selectedInventoryTemplate}
          setSelectedInventoryTemplate={setSelectedInventoryTemplate}
          //------
          selectedFallbackEmail={selectedFallbackEmail}
          setSelectedFallbackEmail={setSelectedFallbackEmail}
          isOwnerEmailSelected={isOwnerEmailSelected}
          setIsOwnerEmailSelected={setIsOwnerEmailSelected}
          //------
          storage={storage}
          emailOptions={getEmailFields()}
          finalRecords={getFinalRecords()}
          inventoryModule={
            relatedListModule
              ? specialModules.includes(relatedListModule)
              : specialModules.includes(module)
          }
          dataCenterUrl={dataCenterUrl}
          //------
          subject={subject}
          setSubject={setSubject}
          emailContent={emailContent}
          setEmailContent={setEmailContent}
          editorType={editorType}
          setEditorType={setEditorType}
          //------
          adminEmail={adminEmail}
          handlePreviewOpen={() => {
            setOpenPreview(true);
          }}
        />
      </Dialog>

      <Dialog dialogOpen={openPreview} handleDialogClose={handlePreviewClose}>
        <EmailComponentPreview
          module={module}
          relatedListModule={relatedListModule}
          tempModule={tempModule}
          finalRecords={getFinalRecords()}
          fromEmail={fromEmail}
          subject={subject}
          emailContent={emailContent}
          handlePreviewClose={handlePreviewClose}
        />
      </Dialog>
    </>
  );
};
